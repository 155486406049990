import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="IRStrat I"/>
            <Banner title="IRStrat I"/>
            {/* <section className="welcome_section_area propuesta">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">Se enfoca en maximizar la calidad de entrega y narrativa de sus materiales
                            de RI, para
                            un sólido posicionamiento y servicio, incluye los siguintes aspectos:</p>
                    </div>
                    <div className="row servicios service_right_sidebar p-0 mb-5">
                        <div className="col-lg-12">
                            <div id="accordion" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion" href="#collapse1"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse1">
                                                <span className="pluse">+</span><span className="minus">-</span>Reportes
                                                y comunicados
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse1" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body">


                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Identidad gráfica corporativa
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Elaboración de boiler plate y
                                                    safe harbour statement
                                                    corporativo
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Estructuración y desarrollo
                                                    gráfico de reporte
                                                    trimestral
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Estructuración y edición
                                                    gráfica de informe anual
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Desplegado interactivo HTML del
                                                    informe anual
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Desplegado interactivo HTML del
                                                    informe de
                                                    sustentabilidad
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion"
                                               href="#collapse2"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse2">
                                                <span className="pluse">+</span><span className="minus">-</span>Materiales
                                                para inversionistas
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse2" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Estructuración y desarrollo
                                                    gráfico de presentación a
                                                    inversionistas
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Creación, estructuración y
                                                    edición gráfica de fact
                                                    sheet
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Desarrollo de Adwords
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion"
                                               href="#collapse3"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Sitio de
                                                RI
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse3" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Estructuración y desarrollo
                                                    gráfico
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Creación de contenido</li>
                                                <li><i className="fa fa-angle-right"></i>Plataforma móvil optimizada
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Gráficas interactivas (incluye
                                                    benchmarking con otros
                                                    valores) y ticker
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Herramientas interactivas
                                                    (múltiplosvaluación,
                                                    principales indicadores de desempeño e información bursátil)
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion"
                                               href="#collapse4"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span
                                                className="minus">-</span> Servicios IR Edge 3.0
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse4" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Web Analytics</li>
                                                <li><i className="fa fa-angle-right"></i>Servidores dedicados y en la
                                                    nube
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section> */}
            <section className="welcome_section_area propuesta">
                <div class="page-content about-icon-box padding-100 padding-bottom-50" className="container">
                    <div class="container">
                        <div class="row">
                            <p>Se enfoca en maximizar la calidad de entrega y narrativa de sus materiales de RI, para un
                                sólido posicionamiento y servicio. Este paquete incluye lo siguiente:</p>
                            <div class="col-md-6 mb-5">
                                <div class="icon-box1">
                                    <i class="pe-7s-config"></i>
                                    <div className="media strategy_item align-items-center strategy_item_service">
                                        <div className="round_icon no_round_icon"><i className="flaticon-research"></i>
                                        </div>
                                        <div className="media-body media-body-service">
                                            <h4 class="title_service">Reportes y comunicados</h4>
                                        </div>
                                    </div>
                                    {/* <h4 class="panel-title"></h4> */}

                                    <ul className="list_style">
                                        <li><i className="fa fa-angle-right"></i>Identidad gráfica corporativa
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Elaboración de boiler plate y
                                            safe harbour statement
                                            corporativo
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Estructuración y desarrollo
                                            gráfico de reporte
                                            trimestral
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Estructuración y edición
                                            gráfica de informe anual
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Desplegado interactivo HTML del
                                            informe anual
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Desplegado interactivo HTML del
                                            informe de
                                            sustentabilidad
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 mb-5">
                                <div class="icon-box1">
                                    <i class="pe-7s-monitor"></i>
                                    <div className="media strategy_item align-items-center strategy_item_service">
                                        <div className="round_icon no_round_icon"><i className="flaticon-money-1"></i>
                                        </div>
                                        <div className="media-body media-body-service">
                                            <h4 class="title_service">Sitio de RI</h4>
                                        </div>
                                    </div>
                                    {/* <h4 class="panel-title">Sitio de RI</h4> */}
                                    <ul className="list_style">
                                        <li><i className="fa fa-angle-right"></i>Estructuración y desarrollo
                                            gráfico
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Creación de contenido</li>
                                        <li><i className="fa fa-angle-right"></i>Plataforma móvil optimizada
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Gráficas interactivas (incluye
                                            benchmarking con otros
                                            valores) y ticker
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Herramientas interactivas
                                            (múltiplos de valuación,
                                            principales indicadores de desempeño e información bursátil)
                                        </li>
                                    </ul>

                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="icon-box1">
                                    <i class="pe-7s-phone"></i>
                                    <div className="media strategy_item align-items-center strategy_item_service">
                                        <div className="round_icon no_round_icon"><i className="fa fa-handshake-o"></i>
                                        </div>
                                        <div className="media-body media-body-service">
                                            <h4 class="title_service">Materiales para inversionistas</h4>
                                        </div>
                                    </div>
                                    {/* <h4 class="panel-title">Materiales para inversionistas</h4> */}
                                    <ul className="list_style">
                                        <li><i className="fa fa-angle-right"></i>Estructuración y desarrollo
                                            gráfico de presentación a
                                            inversionistas
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Creación, estructuración y
                                            edición gráfica de fact
                                            sheet
                                        </li>
                                        <li><i className="fa fa-angle-right"></i>Desarrollo de Adwords
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="icon-box1">
                                    <i class="pe-7s-headphones"></i>
                                    <div className="media strategy_item align-items-center strategy_item_service">
                                        <div className="round_icon no_round_icon"><i className="flaticon-graph"></i>
                                        </div>
                                        <div className="media-body media-body-service">
                                            <h4 class="title_service">Servicios IR Edge 3.0</h4>
                                        </div>
                                    </div>
                                    {/* <h4 class="panel-title">Servicios IR Edge 3.0</h4> */}
                                    <ul className="list_style">
                                        <li><i className="fa fa-angle-right"></i>Web Analytics</li>
                                        <li><i className="fa fa-angle-right"></i>Servidores dedicados y en la
                                            nube
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


        </Layout>
    )
}

export default Contenido
